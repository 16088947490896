import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as env from '../../../../globals/env';
import * as validator from '../../../../validations/login-validation';
import * as _ from 'lodash';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent implements OnInit {

  public faqList: any = [];
  public filteredList: any = [];
  public userData: any;
  public usercount: any = 0;
  public filteredusercount: any = 0;
  public STATIC_PROFILE_IMAGE = env.STATIC_PROFILE_IMAGE;
  public selectAllCheck: Boolean = false;
  public searchText: any;
  selectedOptions: any = '';

  itemList = [];
  selectedItems = [];
  settings = {};

  // table options
  p: number = 1;
  perPage: number = 10;
  perPageArr: any = [5, 10, 20, 50, 100];
  sortValue: any = 'fullName';
  sortOrder: any = 'asc';

  constructor(
    public adminService: AdminService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.adminService.getFAQListing().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let faqList = res.data;
        this.usercount = faqList.length
        this.faqList = faqList.map(data => {
          let retObj = {};
          retObj['faq_id'] = data.faq_id;
          retObj['question'] = data.question;
          retObj['answer'] = data.answer;
          retObj['status'] = data.status;
          return retObj;
        });
        this.filteredList = this.faqList;
        this.sort('createdAt') // thn dsc            
      }
    });
  }


  /**
* @desc Sort table values
* @return json
*/
  sort(value) {
    var faqList = this.filteredList;
    if (this.sortValue == value) {
      this.sortOrder = (this.sortOrder == 'asc') ? 'desc' : 'asc';
    }
    this.sortValue = value;
    faqList = _.orderBy(faqList, [value], [this.sortOrder]); // Use Lodash to sort array by 'name'
    this.filteredList = faqList;
  }

  /**
  * @desc Change user Status
  * @return json
  */
  updateFAQStatus(id, status) {
    let faqList = this.faqList;
    let faq_id = id;
    let faqStatus = (status == 1) ? 0 : 1;

    let userObj = {
      faq_id: faq_id,
      status: faqStatus
    }
    $('.customLoader').fadeIn();
    this.adminService.changeFAQStatus(userObj).subscribe((data: any) => {
      $('.customLoader').fadeOut();
      var res = data.response;
      if (data.status) {
        faqList.forEach(data => {
          if (data.faq_id == id) {
            data.status = faqStatus;
          }
        });
        this.faqList = faqList;
      } else {
        Swal.fire('Oops...', res.msg, 'error')
      }
    });
  }

  deleteDetails(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        let userObj = {
          faq_id: id
        }
        $('.customLoader').fadeIn();
        this.adminService.faqDelete(userObj).subscribe((data: any) => {
          $('.customLoader').fadeOut();
          var res = data.response;
          if (data.status) {
            this.filteredList = this.filteredList.filter(data => (data.faq_id != id));
            Swal.fire('Success!', res.msg, 'success')
          } else {
            Swal.fire('Oops...', res.msg, 'error')
          }
        });
      }
    });
  }

}

