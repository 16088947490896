'use strict';

import * as Helper from '../../../globals/helper';

export function validateCMSDetails(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values

    // Required
    if (Helper.isEmpty(Obj.title)) {
        errros['title'] = 'Title is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.description)) {
        errros['description'] = 'Description is required ';
        returnValidator.isValid = false;
    }
    return returnValidator;
};

export function validateFAQDetails(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values

    // Required
    if (Helper.isEmpty(Obj.question)) {
        errros['question'] = 'Question is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.answer)) {
        errros['answer'] = 'Answer is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.type)) {
        errros['type'] = 'Type is required ';
        returnValidator.isValid = false;
    }
    return returnValidator;
};
