import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import * as validator from '../../../validations/admin/users/userType-validation'; //validation
declare var $: any;
import Swal from 'sweetalert2';
import * as _ from 'lodash';
@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {

  public CertificateList: any = [];
  public filteredList: any = [];
  public usercount: any = 0;
  public certificateDetail: any = [];
  public viewId: any = 0;
  public first_name: String;
  public last_name: String;
  public email: String;
  public domain: String;
  public payment_id: String;
  public order_date: String;
  public approved_date: String;
  public comment: String;
  public certificate: String;
  public searchText: any;
  public status:any;
  public admin_comment: any = '';
  public certificate_number: String;
  public business_name: any;
  public certificate_name: any;
  public industry: any;
  public business_one_sentence: any;
  public business_goals: any;
  public business_publicized: any;
  public main_competitors: any;
  public customers_located: any;
  public business_url: any;

  // table options
  p: number = 1;
  perPage: number = 10;
  perPageArr: any = [5, 10, 20, 50, 100];
  sortValue: any = 'category_name';
  sortOrder: any = 'asc';
  public validationErrors: any = {}; // Error object

  constructor(public adminService: AdminService) { }

  ngOnInit() {
    this.viewCertificateData();
    
  }

  viewCertificateData(){
    this.adminService.getCertificateList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let CertificateList = res.data;
        console.log(CertificateList);
        this.usercount = CertificateList.length;
        this.CertificateList = CertificateList;
        this.filteredList = this.CertificateList;
      }
    });
  }

  viewForm(id) {
    this.certificateDetail = this.CertificateList.filter(data => (data.certificate_request_id == id));
    this.viewId = id;
    this.first_name = this.certificateDetail[0].first_name;
    this.last_name = this.certificateDetail[0].last_name;
    this.comment = this.certificateDetail[0].comment;
    this.status = this.certificateDetail[0].status;
    this.email = this.certificateDetail[0].email;
    this.domain = this.certificateDetail[0].domain;
    this.business_one_sentence = this.certificateDetail[0].business_one_sentence;
    this.business_goals = this.certificateDetail[0].business_goals;
    this.business_publicized = this.certificateDetail[0].business_publicized;
    this.main_competitors = this.certificateDetail[0].main_competitors;
    this.customers_located = this.certificateDetail[0].customers_located;
    this.business_url = this.certificateDetail[0].business_url;
    this.business_name = this.certificateDetail[0].business_name;
    this.certificate_name = this.certificateDetail[0].certificate_name;
    this.industry = this.certificateDetail[0].industry;
    this.payment_id = this.certificateDetail[0].payment_id;
    this.order_date = this.certificateDetail[0].order_date;
    this.approved_date = this.certificateDetail[0].approved_date;    
    this.admin_comment = (this.certificateDetail[0].admin_comment) ? this.certificateDetail[0].admin_comment : '';
    this.certificate = this.certificateDetail[0].certificate; 
    this.certificate_number = this.certificateDetail[0].certificate_number;
  }

  submitRequest(id,status){

    let certiobj = {
      comment: this.admin_comment
    };
    let validate = validator.validateCertificateComment(certiobj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};
      var obj = {
        id: id,
        status: status,
        comment: this.admin_comment.replace(/\r?\n/g, '<br />')
      }
      this.adminService.approveRequest(obj).subscribe((data: any) => {
        var res = data.response;        
        if (data.status) {    
          this.CertificateList.forEach(data => {
            if (data.certificate_request_id == id) {
              data.status = res.data;
              data.admin_comment = this.admin_comment.replace(/\r?\n/g, '<br />');
              data.certificate = res.certificate;
              data.certificate_number = res.number;
            }
          });
          this.admin_comment = '';
          Swal.fire('Success!', data.msg, 'success');
          $(".close").click();
        }else{
          Swal.fire('Oops...', data.msg, 'error')
        }
      });
    }  
  }

  sort(value) {
    var userList = this.filteredList;
    if (this.sortValue == value) {
      this.sortOrder = (this.sortOrder == 'asc') ? 'desc' : 'asc';
    }
    this.sortValue = value;
    userList = _.orderBy(userList, [value], [this.sortOrder]); // Use Lodash to sort array by 'name'
    this.filteredList = userList;
  }


}
