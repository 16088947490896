import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as validator from '../../../../validations/admin/users/userType-validation'; //validation
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray, CdkDragStart, CdkDragRelease } from '@angular/cdk/drag-drop';


import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {

  
  public tag_name: String;
  public tag: String;
  public BrandList: any = [];
  public filteredList: any = [];
  public brandcount: any = 0;
  public validationErrors: any = {}; // Error object
  public filteredusercount: any = 0;
  public userDetail: any = [];
  public updateList: any = [];
  public editId: any = 0;


  // table options
  p: number = 1;
  perPage: number = 5;
  perPageArr: any = [5, 10, 20, 50, 100];
  sortValue: any = 'tag_name';
  sortOrder: any = 'asc';

  columns: any[];  
  pos: any;
  release: boolean = true;
 

  constructor(public adminService: AdminService,
    private router: Router, public renderer2: Renderer2) { }

  ngOnInit() {
    this.adminService.getBrandList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let BrandList = res.data;
        this.brandcount = BrandList.length;
        this.BrandList = BrandList;
        this.filteredList = this.BrandList;
      }
    });

    //this.BrandList = generateItems(50, i => ({ data: 'Draggable ' + i }))

  }  

  dropRow(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.BrandList, event.previousIndex, event.currentIndex);
  }
  dropCol(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    console.log(this.columns, this.BrandList)
  }
  mouseDown(event, el: any = null) {
    el = el || event.target
    this.pos = {
      x: el.getBoundingClientRect().left - event.clientX + 'px',
      y: el.getBoundingClientRect().top - event.clientY + 'px',
      width: el.getBoundingClientRect().width + 'px'
    }
  }
  onDragRelease(event: CdkDragRelease) {
    console.log(event)
    this.renderer2.setStyle(event.source.element.nativeElement, 'margin-left', '0px')
    console.log(this.BrandList)

    setTimeout(() => {
    this.updateList = this.BrandList.map((data, index) => {
      let retObj = {};
      retObj['order'] = index;
      retObj['id'] = data.id;
      return retObj;
    });
      console.log(this.updateList);
    

    var obj = {
      brandlist: this.updateList
    }
      console.log(this.updateList)
      this.adminService.changeBrandOrder(obj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {         
          
        } else {
          
        }
      });

    }, 10);
   
  }

  

  /**
* @desc Change user Status
* @return json
*/
  updateStatus(id, status) {
    let brandTypeList = this.BrandList;
    let brandId = id;
    let catStatus = (status == 1) ? 0 : 1;

    let catObj = {
      brandId: brandId,
      status: catStatus
    }
    $('.customLoader').fadeIn();
    this.adminService.changeBrandStatus(catObj).subscribe((data: any) => {
      $('.customLoader').fadeOut();
      var res = data.response;
      if (data.status) {
        brandTypeList.forEach(data => {
          if (data.id == brandId) {
            data.status = catStatus;
          }
        });
        this.BrandList = brandTypeList;
      } else {
        Swal.fire('Oops...', res.msg, 'error')
      }
    });
  }

}
