import { Component, OnInit } from '@angular/core';
import * as validator from '../../../../validations/admin/static-pages/staticPage-validation';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import Swal from 'sweetalert2';
//import { timeout } from 'rxjs/operator/timeout';

declare var $: any;

@Component({
  selector: 'app-admin-static-page-add',
  templateUrl: './admin-static-page-add.component.html',
  styleUrls: ['./admin-static-page-add.component.scss']
})
export class AdminStaticPageAddComponent implements OnInit {

  public title:any;
  public description: any;
  public validationErrors: any = {}; // Error object

  constructor(public adminService: AdminService, private router: Router) { }

  ngOnInit() {
  }

  addCMSDetails() {

    let btandObj = {
      title: this.title,
      description: this.description
    };

    let validate = validator.validateCMSDetails(btandObj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};

      let obj = {
        title: this.title,
        description: this.description
      };

      this.adminService.addCMSPage(obj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        
        var res = data.response;
        if (data.status) {
          this.router.navigate(['/admin/cmspage/view']);
          Swal.fire('Success!', res.msg, 'success');
        } else {
          
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
     
    }

  }

}
