import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import * as env from '../globals/env';
import { environment } from '../../environments/environment';


@Injectable()
export class AuthenticationService {

    authToken: any;
    user: any;
    baseUrl: any = environment.server_url;

    constructor(private http:HttpClient) { }

    /**
    * Developed Name: Dipika Patel
    * Date: 22-05-2019
    * @desc Login user
    *
    * @return json
    */
    loginUser(user) {
        let headers = new HttpHeaders();
        headers.append('Content-type', 'application/json');
        return this.http.post(this.baseUrl + '/api/user/login', user, {headers: headers});
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 24-04-2018
    * @desc Store login data in local storage
    *
    */
    storeUserData(token, user) {
        localStorage.setItem('Brand_id_token', token);
        localStorage.setItem('Brand_user', JSON.stringify(user));
        this.authToken = token;
        this.user= user;
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 24-04-2018
    * @desc Register User
    *
    * @return json
    */
    registerUser(user) {
        let headers = new HttpHeaders();
        headers.append('Content-type', 'application/json');
        return this.http.post(this.baseUrl + '/api/user/registration', user, {headers: headers});
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 22-05-2019
    * @desc Verify Email
    *
    * @return json
    */
    verifyEmail(obj) {
        let headers = new HttpHeaders();
        headers.append('Content-type', 'application/json');
        return this.http.post(this.baseUrl + '/api/user/email-varification', obj, {headers: headers});
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 24-04-2018
    * @desc Load Token
    *
    */
    loadToken() {
        var token = localStorage.getItem('Brand_id_token');
        this.authToken = token;
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 24-04-2018
    * @desc Get User role
    *
    * @return String
    */
    getRoleName() {
        var user = JSON.parse(localStorage.getItem('Brand_user'));
        return user.userRole;
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 24-04-2018
    * @desc Check if user is logged in
    *
    * @return Boolean
    */
    loggedIn() {
        var token = localStorage.getItem('Brand_id_token');
        var user = localStorage.getItem('Brand_user');
        if (token !== null && user !== null) {
            return true;
        } else {
            return false;
        }
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 24-04-2018
    * @desc Logout user
    *
    */  

    logout() {
        this.authToken = null;
        this.user = null;
        localStorage.removeItem('Brand_Dashboard');
        localStorage.removeItem('Brand_id_token');
        localStorage.removeItem('Domain_info');
        localStorage.removeItem('Brand_user');
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 22-05-2019
    * @desc Forgot password
    *
    * @return json
    */
    forgotPassword(user) {
        let headers = new HttpHeaders();
        headers.append('Content-type', 'application/json');
        return this.http.post(this.baseUrl + '/api/user/forgot-password', user, {headers: headers});
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 22-05-2019
    * @desc Reset password
    *
    * @return json
    */
    resetPassword(Obj) {
        let headers = new HttpHeaders();
        headers.append('Content-type', 'application/json');
        return this.http.post(this.baseUrl + '/api/user/reset-password', Obj, {headers: headers});
    }

}
