import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './component/login/login.component';

// Admin Components
import { AdminDashboardComponent } from './component/admin/admin-dashboard/admin-dashboard.component';
import { AdminSidebarComponent } from './component/admin/admin-sidebar/admin-sidebar.component';
import { AdminHeaderComponent } from './component/admin/admin-header/admin-header.component';


// Manage Users
import { AdminViewUsersComponent } from './component/admin/manage-users/admin-view-users/admin-view-users.component';
import { UserEditComponent } from './component/admin/manage-users/user-edit/user-edit.component';

// Manage Brand
import { BrandListComponent } from './component/admin/manage-brand/brand-list/brand-list.component';
import { BrandAddComponent } from './component/admin/manage-brand/brand-add/brand-add.component';
import { BrandEditComponent } from './component/admin/manage-brand/brand-edit/brand-edit.component';

// Manage Category
import { CategoryListComponent } from './component/admin/manage-category/category-list/category-list.component';

// Manage Orders
import { OrderListComponent } from './component/admin/manage-orders/order-list/order-list.component';
import { OrderViewComponent } from './component/admin/manage-orders/order-view/order-view.component';

// Manage Tag
import { TagListComponent } from './component/admin/manage-tag/tag-list/tag-list.component';

// Manage Static Page
import { AdminStaticPageEditComponent } from './component/admin/manage-static-pages/admin-static-page-edit/admin-static-page-edit.component';
import { AdminStaticPageAddComponent } from './component/admin/manage-static-pages/admin-static-page-add/admin-static-page-add.component';
import { AdminStaticPagesComponent } from './component/admin/manage-static-pages/admin-static-pages/admin-static-pages.component';


// Manage Static Page
import { AddFaqListComponent } from './component/admin/manage-faq/add-faq-list/add-faq-list.component';
import { EditFaqListComponent } from './component/admin/manage-faq/edit-faq-list/edit-faq-list.component';
import { FaqListComponent } from './component/admin/manage-faq/faq-list/faq-list.component';

// Manage about
import { AboutComponent } from './component/admin/about/about.component';

import { CertificateComponent } from './component/admin/certificate/certificate.component';


const routes: Routes = [
  {
    path: '', children: [
      {
        path: '', component: LoginComponent, data: {
          title: 'Forums | Brand',
          metaDescription: 'Brand meta Description',
          metaKeywords: 'Brand, SEA, Brand SEA'
        }
      },      
    ]
  }
];

const adminRoutes: Routes = [
  {
    path: 'admin', children: [
      {
        path: 'dashboard', component: AdminDashboardComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Dashboard | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },      
      {
        path: 'users/view', component: AdminViewUsersComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'View Users | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },  
      {
        path: 'users/edit/:id', component: UserEditComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Edit Users | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },      
      {
        path: 'category', component: CategoryListComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Category | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'tag/view', component: TagListComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'View Tag | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },    
      {
        path: 'brand/view', component: BrandListComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'View brand | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'brand/add', component: BrandAddComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Add brand | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'brand/edit/:id', component: BrandEditComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Edit brand | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },

      {
        path: 'order/view/:id', component: OrderViewComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'View Order | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'order/list', component: OrderListComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Add Order | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },   
      {
        path: 'cmspage/add', component: AdminStaticPageAddComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Add cmsPage | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'cmspage/edit/:id', component: AdminStaticPageEditComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Edit cmsPage | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'cmspage/view', component: AdminStaticPagesComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'View cmsPage | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      }, 
      
      {
        path: 'faq/add', component: AddFaqListComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Add faq | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'faq/edit/:id', component: EditFaqListComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'Edit faq | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'faq/view', component: FaqListComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'View faq | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'about', component: AboutComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'View about | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      {
        path: 'certificate', component: CertificateComponent, canActivate: [AuthGuard], data: {
          roles: [1],
          title: 'View certificate | Brand',
          metaDescription: '',
          metaKeywords: ''
        }
      },
      { path: '', component: AdminHeaderComponent, canActivate: [AuthGuard], outlet: 'adminheader', data: { roles: [1] } },
      { path: '', component: AdminSidebarComponent, canActivate: [AuthGuard], outlet: 'adminsidebar', data: { roles: [1] } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), RouterModule.forRoot(adminRoutes)],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
