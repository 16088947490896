import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { AdminService } from '../../../services/admin.service';
import * as env from '../../../globals/env';

import Swal from 'sweetalert2'
declare var $:any;

@Component({
    selector: 'app-admin-header',
    templateUrl: './admin-header.component.html',
    styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

    public userNotificationList: any = [];
    public postNotificationList: any = [];
    public groupNotificationList: any = [];

    public groupNotificationCount: any = [];

    public userPopupOpen: Boolean = false;
    public postPopupOpen: Boolean = false;
    public groupPopupOpen: Boolean = false;    
    public LOGO: any = env.STATIC_PROFILE_IMAGE;
    

    constructor(
        public authService: AuthenticationService,
        public adminService: AdminService,
        private router: Router,
    ) { }

    ngOnInit() {
        let firstVisit = localStorage.getItem('Brand_Dashboard');
        if (firstVisit == null) {
            localStorage.setItem('Brand_Dashboard', '1');
            location.reload();
        }
       
    }

   
    /**
    * @desc: Get post notification list
    * @return: false
    **/
    onLogout() {
        this.authService.logout();
        localStorage.removeItem('Brand_Dashboard');
        this.router.navigate(['/']);
        return false;
    }

}
