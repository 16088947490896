import { Component, OnInit, ViewChild } from '@angular/core';
import * as validator from '../../../validations/admin/about/about-validation'; //validation
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AdminService } from '../../../services/admin.service';
declare var ClassicEditor: any;


declare var $: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public title: any;
  public title_content: any;

  public secdmages: any;
  public secondImage: any;
  public sectionone_content: any;
  public sectiontwo_content: any;

  public first_title: any;
  public firstmages: any;
  public firstImage: any;
  public first_description: any;

  public second_title: any;
  public secondmages: any;
  public secondsImage: any;
  public second_description: any;

  public third_title: any;
  public thirdmages: any;
  public thirdsImage: any;
  public third_description: any;


  public thirdsection_maincontent_top: any;
  public thirdsection_maincontent_middle: any;
  public thirdsection_maincontent_bottom: any;
  public thirdsection_subcontent: any;
  public singmages: any;
  public singnatureImage: any;

  public ownername: any;
  public position: any;

  public about: any;

  public content: any;
  public maincontent_top: any;
  public maincontent_middle: any;
  public maincontent_bottom: any;


  public fourthsection_display: boolean;
  public fourthsection_maincontent: any;
  public fourthsection_title: string;
  public fourthsection_image: any;
  public fourthsection_new_image: any;

  public fifthsection_display: boolean;
  public fifthsection_maincontent: any;
  public fifthsection_title: string;
  public fifthsection_image: any;
  public fifthsection_new_image: any;

  public validationErrors: any = {}; // Error object

  name = 'ng2-ckeditor';

  mycontent: string;
  log: string = '';



  constructor(public adminService: AdminService,
    private router: Router,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    ClassicEditor.create(document.querySelector('#sectionone_content'))
      .then(editor => {
        this.content = editor;

      })
      .catch(error => {
      });

    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '200px')
    })


    ClassicEditor.create(document.querySelector('#thirdsection_maincontent_top'))
      .then(editor => {
        this.maincontent_top = editor;
      })
      .catch(error => {
      });
    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '150px')
    })

    ClassicEditor.create(document.querySelector('#thirdsection_maincontent_middle'))
      .then(editor => {
        this.maincontent_middle = editor;
      })
      .catch(error => {
      });
    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '150px')
    })

    ClassicEditor.create(document.querySelector('#thirdsection_maincontent_bottom'))
      .then(editor => {
        this.maincontent_bottom = editor;
      })
      .catch(error => {
      });
    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '150px')
    })


    ClassicEditor.create(document.querySelector('#fourthsection_maincontent'))
      .then(editor => {
        this.fourthsection_maincontent = editor;
      })
      .catch(error => {
      });
    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '150px')
    })

    ClassicEditor.create(document.querySelector('#fifthsection_maincontent'))
      .then(editor => {
        this.fifthsection_maincontent = editor;
      })
      .catch(error => {
      });
    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '150px')
    })



    this.adminService.getAboutDetails().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {


        this.about = res.data;

        this.title = this.about.title
        this.title_content = this.about.title_content
        this.sectionone_content = this.content.setData(this.about.sectionone_content)
        this.sectiontwo_content = this.about.sectiontwo_content
        this.first_title = this.about.first_title
        this.first_description = this.about.first_description
        this.second_title = this.about.second_title
        this.second_description = this.about.second_description
        this.third_title = this.about.third_title
        this.third_description = this.about.third_description
        this.thirdsection_maincontent_top = this.maincontent_top.setData(this.about.thirdsection_maincontent_top)
        this.thirdsection_maincontent_middle = this.maincontent_middle.setData(this.about.thirdsection_maincontent_middle)
        this.thirdsection_maincontent_bottom = this.maincontent_bottom.setData(this.about.thirdsection_maincontent_bottom)

        this.thirdsection_subcontent = this.about.thirdsection_subcontent
        this.ownername = this.about.ownername
        this.position = this.about.position

        this.fourthsection_display = this.about.fourthsection_display;
        this.fourthsection_title = this.about.fourthsection_title;
        this.fourthsection_maincontent.setData(this.about.fourthsection_maincontent)

        this.fifthsection_display = this.about.fifthsection_display
        this.fifthsection_title = this.about.fifthsection_title;
        this.fifthsection_maincontent.setData(this.about.fifthsection_maincontent)

        if (this.about.secondImage) {
          this.secdmages = this.about.secondImage;
        }
        if (this.about.firstImage) {
          this.firstmages = this.about.firstImage;
        }

        if (this.about.secondsImage) {
          this.secondmages = this.about.secondsImage;
        }
        if (this.about.thirdsImage) {
          this.thirdmages = this.about.thirdsImage;
        }
        if (this.about.singnatureImage) {
          this.singmages = this.about.singnatureImage;
        }

        if (this.about.fourthsection_image) {
          this.fourthsection_image = this.about.fourthsection_image
        }

        if (this.about.fifthsection_image) {
          this.fifthsection_image = this.about.fifthsection_image
        }

        $('#smallLoader').hide();

      }
    });

  }




  onSecongImageFileChange(event: any) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.secdmages = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.secondImage = event.target.files[0];
    }
  }

  onFirstImageFileChange(event: any) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.firstmages = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.firstImage = event.target.files[0];
    }
  }

  onSecondImageFileChange(event: any) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.secondmages = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.secondsImage = event.target.files[0];
    }
  }

  onThirdImageFileChange(event: any) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.thirdmages = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.thirdsImage = event.target.files[0];
    }
  }

  onSignatureFileChange(event: any) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.singmages = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.singnatureImage = event.target.files[0];
    }
  }

  onFourthImageChange(event: any) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.fourthsection_image = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.fourthsection_new_image = event.target.files[0];
    }
  }


  onFifthImageChange(event: any) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.fifthsection_image = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.fifthsection_new_image = event.target.files[0];
    }
  }


  addAboutDetails() {

    let btandObj = {
      title: this.title,
      title_content: this.title_content
    };

    let validate = validator.validateAboutDetails(btandObj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};

      let finalbrandObj = {
        title: this.title,
        title_content: this.title_content,
        sectionone_content: this.content.getData(),
        sectiontwo_content: this.sectiontwo_content,
        first_title: this.first_title,
        first_description: this.first_description,
        second_title: this.second_title,
        second_description: this.second_description,
        third_title: this.third_title,
        third_description: this.third_description,
        thirdsection_maincontent_top: this.maincontent_top.getData(),
        thirdsection_maincontent_middle: this.maincontent_middle.getData(),
        thirdsection_maincontent_bottom: this.maincontent_bottom.getData(),
        thirdsection_subcontent: this.thirdsection_subcontent,
        fourthsection_title: this.fourthsection_title,
        fourthsection_maincontent: this.fourthsection_maincontent.getData(),
        fourthsection_display: this.fourthsection_display,
        fifthsection_title: this.fifthsection_title,
        fifthsection_maincontent: this.fifthsection_maincontent.getData(),
        fifthsection_display: this.fifthsection_display,
        ownername: this.ownername,
        position: this.position
      }

      const formData: any = new FormData();
      formData.append("data", JSON.stringify(finalbrandObj));


      if (this.secondImage) {
        formData.append("secondImage", this.secondImage, this.secondImage.name);
      }
      if (this.firstImage) {
        formData.append("firstImage", this.firstImage, this.firstImage.name);
      }
      if (this.secondsImage) {
        formData.append("secondsImage", this.secondsImage, this.secondsImage.name);
      }
      if (this.thirdsImage) {
        formData.append("thirdsImage", this.thirdsImage, this.thirdsImage.name);
      }
      if (this.singnatureImage) {
        formData.append("singnatureImage", this.singnatureImage, this.singnatureImage.name);
      }
      if (this.fourthsection_new_image) {
        formData.append('fourthSectionImage', this.fourthsection_new_image, this.fourthsection_new_image.name)
      }
      if (this.fifthsection_new_image) {
        formData.append('fifthSectionImage', this.fifthsection_new_image, this.fifthsection_new_image.name)
      }

      $('.customLoader').fadeIn();

      this.adminService.addAbout(formData).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          Swal.fire('Success!', res.msg, 'success');
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }

  }


}
