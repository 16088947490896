'use strict';

/**
* @desc Check empty String
* @return Boolean
*
*/
export const isEmpty = function(val) {
    if (val === undefined || val === '') {
        return true;
    } else {
        return false;
    }
}

/**
* @desc Check for valid String
* @return Boolean
*
*/
export const isStirng = function(value) {
    var str = value;
    var myRegEx  = /^[a-zA-Z\s]*$/;
    var isValid = myRegEx.test(str);
    if(isValid){
        return true;
    }else{
        return false;
    }
}

// check for Numaric value
export const isNumber = function(value) {
    var number = value;
    var myRegEx  = /^(\s*[0-9]+\s*)+$/;
    var isValid = myRegEx.test(number);
    if(isValid){
        return true;
    }else{
        return false;
    }
}

/**
* @desc Check Valid Email
* @return Boolean
*
*/
export const isValidEmail = function(val) {
    var email = val;
    var myRegEx  = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = myRegEx.test(email);
    if(isValid){
        return true;
    }else{
        return false;
    }
}

/**
* @desc Check Valid Mobile
* @return Boolean
*
*/
export const isValidMobile = function(val) {
    var number = val;
    var myRegEx  = /^(\+\d{1,3}[- ]?)?\d{6,20}$/;
    var isValid = myRegEx.test(number);
    if(isValid){
        return true;
    }else{
        return false;
    }
}

/**
* @desc Check Valid Url
* @return Boolean
*
*/
export const isValidUrl = function(str) {
    var regexp = new RegExp("^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$");
    if(!regexp.test(str)) {
        return false;
    } else {
        return true;
    }
}

