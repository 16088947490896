import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from '../../../services/admin.service';


declare var $: any;


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {


  public startdate: any;
  public enddate: any;
  public commentCount = 0;
  public revenue = 0;
  public total_brand = 0;
  public active_client = 0;
  public total_order = 0;

  public orderList = [];

  constructor(
    public adminService: AdminService,

  ) { }

  ngOnInit() {
   

    this.adminService.getAnalytics().subscribe((data: any) => {
        var res = data.response;

      if (data.status) {
        this.revenue = res.data.revenue;
        this.total_brand = res.data.total_brand;
        this.active_client = res.data.active_client;
        this.total_order = res.data.total_order;
      }
    });

    this.adminService.getLatestOrderListing().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let orderList = res.data;
        this.orderList = orderList
      }else{
        this.orderList = [];
      }
    });
  }

  


 

}

