import { Injectable } from '@angular/core';

@Injectable()
export class SeoService {

  constructor() { }

  /**
  * Developed Name: Parth Patwala
  * Date: 02-April-2018
  * Modified By:
  * Modified Date:
  * Description: Get title service
  *
  * @return json
  */
  getTitleValue(state, parent) {
      var data = [];
      // push title
      if(parent && parent.snapshot.data && parent.snapshot.data.title) {
          data.push(parent.snapshot.data.title);
      }
      // push metaDescription
      if(parent && parent.snapshot.data && parent.snapshot.data.metaDescription) {
          data.push(parent.snapshot.data.metaDescription);
      }
      // push kqywords
      if(parent && parent.snapshot.data && parent.snapshot.data.metaKeywords) {
          data.push(parent.snapshot.data.metaKeywords);
      }

      if(state && parent) {
          data.push(... this.getTitleValue(state, state.firstChild(parent)));
      }
      return data;
  }

}
