import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()

export class AuthGuard implements CanActivate{
    constructor(private authService: AuthenticationService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
        if(this.authService.loggedIn()) {
            let roles = route.data["roles"] as Array<string>;
            let roleName = this.authService.getRoleName();
            if( roles == null || roles.indexOf(roleName) != -1) {
                return true;
            } else {
                if (roleName === 1) {
                    this.router.navigate(['/admin/dashboard']);
                } else {
                    this.router.navigate(['/']);
                }
            }
        } else  {
            this.router.navigate(['/']);
        }
    }
}
