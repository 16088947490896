import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { AdminService } from '../../../../services/admin.service';

import * as validator from '../../../../validations/admin/users/editUser-validation'; //validation
import * as env from '../../../../globals/env';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  public user: any;
  public categoryList: any = []; // Country list object

  // Required fileds
  public fullName: String;
  public userName: String;
  public email: String;
  public phone: String;

  /** contact details section **/
  public portfoliourl: any;
  public twitteraccount: any;
 

  public validationErrors: any = {}; // Error object

  constructor(
    public commonService: CommonService,
    public adminService: AdminService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    

    this.activatedRoute.params.subscribe((params: Params) => {
      let userId = params['id'];

      let userObj = {
        user_id: userId
      }

      this.adminService.getUserDetails(userObj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
          this.user = res.data;

          console.log(this.user);
          
          this.fullName = this.user.fullName;
          this.userName = this.user.userName;
          this.email = this.user.email;
          this.phone = this.user.phone;
          
          // Personal details          
          this.portfoliourl = this.user.portfoliourl;
          this.twitteraccount = this.user.twitteraccount;
          

        } else {
          this.router.navigate(['/admin/users/view']);
        }
      });
    });
  }



  initUpdateUser() {
    let fullName = this.fullName;
    let userName = this.userName;
    let email = this.email;
    let phone = this.phone;
    let portfoliourl = this.portfoliourl;
    let twitteraccount = this.twitteraccount;
   

    let registerObj = {
      fullName: fullName,
      userName: userName
    }

    let validate = validator.validateEditUser(registerObj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      console.log(this.validationErrors)
      return false;
    } else {
      this.validationErrors = {}

      let editUserObj = {
        fullName: fullName,
        userName: userName,
        email: email,
        userId: this.user.id,
        phone: phone,
        portfoliourl: portfoliourl,
        twitteraccount: twitteraccount
      };
      $('.customLoader').fadeIn();
      console.log('editUserObj=' + editUserObj)
      this.adminService.updateUser(editUserObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          this.router.navigate(['/admin/users/view']);
          Swal.fire('Success!', res.msg, 'success')
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

}
