import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as validator from '../../../../validations/admin/static-pages/staticPage-validation'; 


declare var ClassicEditor: any;
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-admin-static-page-edit',
  templateUrl: './admin-static-page-edit.component.html',
  styleUrls: ['./admin-static-page-edit.component.scss']
})
export class AdminStaticPageEditComponent implements OnInit {

  public title: any;
  public pageID: any;
  public slug: any;
  public content: any = '';
  public description: any;
  public validationErrors: any = {}; // Error object

  constructor(
    public adminService: AdminService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {

   

    ClassicEditor.create(document.querySelector('#editor'))
      .then(editor => {
        this.content = editor;
        console.log(this.content.getData());
        
      })
      .catch(error => {
      });

    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '200px')
    })

      

    this.activatedRoute.params.subscribe((params: Params) => {
      let pageId = params['id'];
      let pageObj = {
        page_id: pageId
      }
      this.adminService.getCMSPageDetails(pageObj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
          
          
          this.pageID = res.data.page_id;
          this.title = res.data.title;
         // this.slug = res.data.slug;
          this.description = this.content.setData(res.data.description);
         
         
          
        }
      });
      
    });
  }

  updateCMSDetails(){   
    
    let btandObj = {
      title: this.title,
      description: this.content.getData()
    };

    let validate = validator.validateCMSDetails(btandObj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};

      let obj = {
        title: this.title,
        description: this.content.getData(),
        page_id: this.pageID
      };
      this.adminService.editCMSPage(obj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          this.router.navigate(['/admin/cmspage/view']);
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

}
