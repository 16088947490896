import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import * as env from '../globals/env';
import { environment } from '../../environments/environment';

@Injectable()
export class AdminService {

    authToken: any;
    user: any;
    baseUrl: any = environment.server_url;

    constructor(private http: HttpClient) { }

    /**
   * Developed Name: Dipika Patel
   * Date: 08-08-2021
   * @desc Get all category type list
   *
   * @return json
   */

    getAnalytics() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/category/getAnalytics', { headers: headers });
    }

    getCategoryList() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/category/getCategoryList', { headers: headers });
    }

    getActiveCategoryList() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/category/getActiveCategoryList', { headers: headers });
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 08-08-2021
    * @desc Add cat type
    *
    * @return json
    */
    addCategoryType(Obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/addCategoryType', Obj, { headers: headers });
    }


    /**
    * Developed Name: Dipika Patel
    * Date:  08-08-2021
    * @desc update category detail
    * @return json
    */
    changeCategoryDetail(catObj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/changeCategoryDetail', catObj, { headers: headers });
    }

    /**
     * Developed Name: Dipika Patel
     * Date:  08-08-2021
     * @desc update category detail
     * @return json
     */
    changeCategoryStatus(catObj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/changeCategoryStatus', catObj, { headers: headers });
    }


    /**
 * Developed Name: Dipika Patel
 * Date: 08-08-2021
 * @desc Get all category type list
 *
 * @return json
 */
    getTagList() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/tag/getTagList', { headers: headers });
    }

    getActiveTagList() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/tag/getActiveTagList', { headers: headers });
    }



    /**
    * Developed Name: Dipika Patel
    * Date: 08-08-2021
    * @desc Add cat type
    *
    * @return json
    */
    addTagType(Obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/tag/addTagType', Obj, { headers: headers });
    }


    /**
    * Developed Name: Dipika Patel
    * Date:  08-08-2021
    * @desc update Tag detail
    * @return json
    */
    changeTagDetail(catObj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/tag/changeTagDetail', catObj, { headers: headers });
    }

    /**
     * Developed Name: Dipika Patel
     * Date:  08-08-2021
     * @desc update Tag detail
     * @return json
     */
    changeTagStatus(catObj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/tag/changeTagStatus', catObj, { headers: headers });
    }

    /**
   * Developed Name: Dipika
   * Date: 09-08-2021
   * @desc add banner
   *
   * @return json
   */
    addBrand(Obj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/brand/addBrand', Obj, { headers: headers });
    }

    editBrand(Obj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/brand/editBrand', Obj, { headers: headers });
    }

    getBrandList() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/brand/getBrandList', { headers: headers });
    }


    getBrandDetails(obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/brand/getBrandDetails', obj, { headers: headers });
    }



    /**
    * Developed Name: Dipika Patel
    * Date: 26-04-2018
    * @desc Change user status
    *
    * @return json
    */
    changeUserStatus(userObj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/user/changeUserStatus', userObj, { headers: headers });
    }

    changeBrandStatus(userObj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/brand/changeBrandStatus', userObj, { headers: headers });
    }

    changeBrandOrder(Obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/brand/changeBrandOrder', Obj, { headers: headers });
    }

    getUserListing() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/user/userList', { headers: headers });
    }

    getOrderListing() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/cart/getOrderListing', { headers: headers });
    }

    getFilterOrderListing(obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/cart/getFilterOrderListing', obj, { headers: headers });
    }

    getLatestOrderListing() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/cart/getLatestOrderListing', { headers: headers });
    }

    getOrderDetail(obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/cart/getOrderDetail', obj, { headers: headers });
    }

    getcmsListing() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/category/getcmsListing', { headers: headers });
    }

    addCMSPage(Obj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/addCMSPage', Obj, { headers: headers });
    }

    editCMSPage(Obj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/editCMSPage', Obj, { headers: headers });
    }


    getCMSPageDetails(obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/getCMSPageDetails', obj, { headers: headers });
    }


    getFAQListing() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/category/getFAQListing', { headers: headers });
    }

    addFAQPage(Obj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/addFAQPage', Obj, { headers: headers });
    }

    editFAQPage(Obj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/editFAQPage', Obj, { headers: headers });
    }


    getFAQPageDetails(obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/getFAQPageDetails', obj, { headers: headers });
    }

    changeFAQStatus(Obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/changeFAQStatus', Obj, { headers: headers });
    }

    faqDelete(Obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/faqDelete', Obj, { headers: headers });
    }

    addAboutSection(Obj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/about', Obj, { headers: headers });
    }

    getAboutSections() {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/about', { headers: headers });
    }

    addAbout(Obj) {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/category/addAbout', Obj, { headers: headers });
    }

    getAboutDetails() {
        let headers = new HttpHeaders();
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/category/getAboutDetails', { headers: headers });
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 30-04-2018
    * @desc Add new User
    * @return json
    */
    addNewUser(userObj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/user/addUser', userObj, { headers: headers });
    }

    /**
    * Developed Name: Dipika Patel
    * Date: 30-04-2018
    * @desc Get user information
    *
    * @return json
    */
    getUserDetails(userObj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/user/getuserData', userObj, { headers: headers });
    }


    /**
    * Developed Name: Dipika Patel
    * Date: 30-04-2018
    * @desc Update User Details
    *
    * @return json
    */
    updateUser(userObj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/user/editUser', userObj, { headers: headers });
    }

    getCertificateList() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.get(this.baseUrl + '/api/certificate/getCertificateList', { headers: headers });
    }

    approveRequest(obj) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.loadToken();
        headers = headers.set('Authorization', this.authToken);
        return this.http.post(this.baseUrl + '/api/certificate/approveRequest', obj, { headers: headers });
    }




    // ***********************************************************************************************


    loadToken() {
        var token = localStorage.getItem('Brand_id_token');
        this.authToken = token;
    }



}
