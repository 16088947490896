import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as env from '../../../../globals/env';
import * as validator from '../../../../validations/login-validation';
import * as _ from 'lodash';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
    selector: 'app-admin-view-users',
    templateUrl: './admin-view-users.component.html',
    styleUrls: ['./admin-view-users.component.scss']
})
export class AdminViewUsersComponent implements OnInit {
    
    public userList: any = [];
    public filteredList: any = [];
    public userData: any;
    public usercount: any = 0;
    public filteredusercount: any = 0;
    public STATIC_PROFILE_IMAGE = env.STATIC_PROFILE_IMAGE;
    public selectAllCheck: Boolean = false;
    public searchText: any;
    selectedOptions: any = '';

    itemList = [];
    selectedItems = [];
    settings = {};

    // table options
    p: number = 1;
    perPage: number = 10;
    perPageArr: any = [5, 10, 20, 50, 100];
    sortValue: any = 'fullName';
    sortOrder: any = 'asc';

    constructor(
        public adminService: AdminService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.adminService.getUserListing().subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
                let userList = res.data;
                this.usercount = userList.length
                this.userList = userList.map(data => {
                    let retObj = {};
                    retObj['id'] = data.id;                    
                    retObj['fullName'] = data.fullName;
                    retObj['userName'] = data.userName;
                    retObj['email'] = data.email;                    
                    retObj['status'] = data.status;
                    retObj['phone'] = data.phone;
                    retObj['usertype'] = data.usertype;
                    retObj['createdAt'] = data.createdAt;
                    return retObj;
                });
                this.filteredList = this.userList;
                this.sort('createdAt') // thn dsc            
            }
        });
    }


    /**
  * @desc Sort table values
  * @return json
  */
    sort(value) {
        var userList = this.filteredList;
        if (this.sortValue == value) {
            this.sortOrder = (this.sortOrder == 'asc') ? 'desc' : 'asc';
        }
        this.sortValue = value;
        userList = _.orderBy(userList, [value], [this.sortOrder]); // Use Lodash to sort array by 'name'
        this.filteredList = userList;
    }

    /**
    * @desc Change user Status
    * @return json
    */
    updateStatus(id, status) {
        let userList = this.userList;
        let userId = id;
        let userStatus = (status == 1) ? 0 : 1;

        let userObj = {
            userId: userId,
            status: userStatus
        }
        $('.customLoader').fadeIn();
        this.adminService.changeUserStatus(userObj).subscribe((data: any) => {
            $('.customLoader').fadeOut();
            var res = data.response;
            if (data.status) {
                userList.forEach(data => {
                    if (data.id == id) {
                        data.status = userStatus;
                    }
                });
                this.userList = userList;
            } else {
                Swal.fire('Oops...', res.msg, 'error')
            }
        });
    }

    initViewUser(id) {
        let userId = id;
        let userObj = {
            user_id: userId
        }
        this.adminService.getUserDetails(userObj).subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
                this.userData = res.data;
            } else {
                this.router.navigate(['/admin/users/view']);
            }
        });
    }



    
}
