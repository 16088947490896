import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-view',
  templateUrl: './brand-view.component.html',
  styleUrls: ['./brand-view.component.scss']
})
export class BrandViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
