import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../../services/admin.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {

  public order_id:any;
  public OrderList = [];

  constructor(public adminService: AdminService, private router: Router,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params: Params) => {
      this.order_id = params['id'];

      let brandObj = {
        order_id: this.order_id
      }     

      this.adminService.getOrderDetail(brandObj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
          this.OrderList = res.data;
          console.log(this.OrderList);
          
        }
      });
    });

   

  }

}
