'use strict';

import * as Helper from '../../../globals/helper';

// server config
export function validateBrandDetails(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values

    // Required
    if (Helper.isEmpty(Obj.name)) {
        errros['name'] = 'Name is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.domain)) {
        errros['domain'] = 'domain is required ';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.extension)) {
        errros['extension'] = 'Extension is required ';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.total_letter)) {
        errros['total_letter'] = 'Total letter is required ';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.brand_status)) {
        errros['brand_status'] = 'Brand status is required ';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.leaseprice)) {
        errros['leaseprice'] = 'Lease price is required ';
        returnValidator.isValid = false;
    } 
    else if (!Helper.isNumber(Obj.leaseprice)) {
        errros['leaseprice'] = 'Lease price should be number.';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.price)) {
        errros['price'] = 'Price is required ';
        returnValidator.isValid = false;
    }
    else if (!Helper.isNumber(Obj.price)) {
        errros['price'] = 'Price should be number.';
        returnValidator.isValid = false;
    }
    

    if (Obj.category.length == 0) {
        errros['category'] = 'Category is required ';
        returnValidator.isValid = false;
    }

    if (Obj.keyword.length == 0) {
        errros['keyword'] = 'Keyword is required ';
        returnValidator.isValid = false;
    }

    if (Obj.zipfile && Obj.zipfile.size > (1024 * 1024 * 25)) {        
        errros['zipfile'] = 'Zip file size should not be grater than 25 MB';
        returnValidator.isValid = false;
    }

    if (Obj.audiofile && Obj.audiofile.size > (1024 * 1024 * 25)) {
        errros['audiofile'] = 'Audio file size should not be grater than 25 MB';
        returnValidator.isValid = false;
    }

    return returnValidator;
};

// server config
export function validateOrderFilter(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values

    // Required
    if (Obj.user == 0) {
        errros['user'] = 'User is required ';
        returnValidator.isValid = false;
    }

    return returnValidator;
};



