import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as validator from '../../../../validations/admin/users/userType-validation'; //validation
import * as _ from 'lodash';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {

  public userType: String;
  public tag_name: String;
  public tag: String;
  public TagList: any = [];
  public filteredList: any = [];
  public usercount: any = 0;
  public validationErrors: any = {}; // Error object
  public filteredusercount: any = 0;
  public userDetail: any = [];
  public editId: any = 0;


  // table options
  p: number = 1;
  perPage: number = 10;
  perPageArr: any = [5, 10, 20, 50, 100];
  sortValue: any = 'tag_name';
  sortOrder: any = 'asc';

  constructor(
    public adminService: AdminService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.adminService.getTagList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let TagList = res.data;
        this.usercount = TagList.length;
        this.TagList = TagList;
        this.filteredList = this.TagList;
      }
    });
  }

  /**
  * @desc Add new Member type
  * @return json
  */
  addNewTagType() {
    let catObj = {
      tag: this.tag,
    }
    let validate = validator.validateTagType(catObj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {}
      let addCatObj = {
        tag_name: this.tag,
      }
      $('.customLoader').fadeIn();
      this.adminService.addTagType(addCatObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        this.tag = '';
        var res = data.response;
        if (data.status) {
          this.TagList.splice(0, 0, res.data);
          $('#addModel').modal('hide');
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  editForm(id) {
    this.userDetail = this.TagList.filter(data => (data.id == id));
    this.editId = this.userDetail[0].id;
    this.tag_name = this.userDetail[0].tag_name;
  }

  editTag() {
    let catObj = {
      catId: this.editId,
      tag: this.tag_name,
    }
    let validate = validator.validateTagType(catObj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {}
      $('.customLoader').fadeIn();
      this.adminService.changeTagDetail(catObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          this.TagList.forEach(data => {
            if (data.id == this.editId) {
              data.tag_name = res.data.tag_name;
            }
          });
          $('#editModel').modal('hide');
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  /**
 * @desc Change user Status
 * @return json
 */
  updateStatus(id, status) {
    let catTypeList = this.TagList;
    let catId = id;
    let catStatus = (status == 1) ? 0 : 1;

    let catObj = {
      catId: catId,
      status: catStatus
    }
    $('.customLoader').fadeIn();
    this.adminService.changeTagStatus(catObj).subscribe((data: any) => {
      $('.customLoader').fadeOut();
      var res = data.response;
      if (data.status) {
        catTypeList.forEach(data => {
          if (data.id == catId) {
            data.status = catStatus;
          }
        });
        this.TagList = catTypeList;
      } else {
        Swal.fire('Oops...', res.msg, 'error')
      }
    });
  }

  /**
 * @desc Sort table values
 * @return json
 */
  sort(value) {
    var userList = this.filteredList;
    if (this.sortValue == value) {
      this.sortOrder = (this.sortOrder == 'asc') ? 'desc' : 'asc';
    }
    this.sortValue = value;
    userList = _.orderBy(userList, [value], [this.sortOrder]); // Use Lodash to sort array by 'name'
    this.filteredList = userList;
  }

}
