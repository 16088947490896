import { Component, OnInit } from '@angular/core';
import * as validator from '../../../../validations/admin/static-pages/staticPage-validation';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
declare var ClassicEditor: any;

import Swal from 'sweetalert2';
//import { timeout } from 'rxjs/operator/timeout';

declare var $: any;

@Component({
  selector: 'app-add-faq-list',
  templateUrl: './add-faq-list.component.html',
  styleUrls: ['./add-faq-list.component.scss']
})
export class AddFaqListComponent implements OnInit {

  public question: any;
  public answer: any;
  public validationErrors: any = {}; // Error object

  public content: any;
  public type: any;

  constructor(public adminService: AdminService, private router: Router) { }

  ngOnInit() {

    ClassicEditor.create(document.querySelector('#answer'))
      .then(editor => {
        this.content = editor;
        console.log(this.content.getData());

      })
      .catch(error => {
      });

    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '200px')
    })

    var bstatus = [{ id: 1, text: "Available" }, { id: 2, text: "How BrandsDotCom Works?" }, { id: 3, text: "Purchasing a Name" }, { id: 4, text: "Leasing a Name" }];

    $('#select1').select2({
      placeholder: "Select type",
      width: '100%',
      data: function () { return { results: bstatus }; }
    });

  }

  addFAQetails() {

    let btandObj = {
      question: this.question,
      answer: this.content.getData(),
      type: $("#select1").val()
    };

    let validate = validator.validateFAQDetails(btandObj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};

      let obj = {
        question: this.question,
        answer: this.content.getData(),
        type: $("#select1").val()
      };

      this.adminService.addFAQPage(obj).subscribe((data: any) => {
        $('.customLoader').fadeOut();

        var res = data.response;
        if (data.status) {
          this.router.navigate(['/admin/faq/view']);
          Swal.fire('Success!', res.msg, 'success');
        } else {

          Swal.fire('Oops...', res.msg, 'error')
        }
      });

    }

  }

}

