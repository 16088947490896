import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as validator from '../../../../validations/admin/users/userType-validation'; //validation
import * as _ from 'lodash';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  public userType: String;
  public category_name: String;
  public category: String;
  public CategoryList: any = [];
  public filteredList: any = [];
  public usercount: any = 0;
  public validationErrors: any = {}; // Error object
  public filteredusercount: any = 0;
  public userDetail: any = [];
  public editId: any = 0;
  public top_industry: number = 0;
 

  // table options
  p: number = 1;
  perPage: number = 10;
  perPageArr: any = [5, 10, 20, 50, 100];
  sortValue: any = 'category_name';
  sortOrder: any = 'asc';

  constructor(
    public adminService: AdminService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.adminService.getCategoryList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let CategoryList = res.data;
        this.usercount = CategoryList.length;
        this.CategoryList = CategoryList;
        this.filteredList = this.CategoryList;
      }
    });
  }

  updateTopIndustry(isChecked: boolean) {
    if (isChecked) {
      this.top_industry = 1;
    } else {
      this.top_industry = 0;
    }
  }

  /**
  * @desc Add new Member type
  * @return json
  */
  addNewCategoryType() {
    let catObj = {
      category: this.category,
    }
    let validate = validator.validateAddUserType(catObj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {}
      let addCatObj = {
        category_name: this.category,
        top_industry: this.top_industry
      }
      $('.customLoader').fadeIn();
      this.adminService.addCategoryType(addCatObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        this.category = '';
        this.top_industry = 0;
        if (data.status) {
          this.CategoryList.splice(0, 0, res.data);
          $('#addModel').modal('hide');
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  editForm(id) {
    this.userDetail = this.CategoryList.filter(data => (data.id == id));
    this.editId = id;
    this.category_name = this.userDetail[0].category_name;    
    this.top_industry = this.userDetail[0].top_industry;
  }
  
  

  editCategory() {    
    let catObj = {
      catId: this.editId,
      category: this.category_name,
      top_industry: this.top_industry
    }   
    let validate = validator.validateAddUserType(catObj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {}
      $('.customLoader').fadeIn();
      this.adminService.changeCategoryDetail(catObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          this.CategoryList.forEach(data => {
            if (data.id == this.editId) {
              data.category_name = res.data.category_name;
              data.top_industry = res.data.top_industry;
            }
          });
          $('#editModel').modal('hide');
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  /**
 * @desc Change user Status
 * @return json
 */
  updateStatus(id, status) {
    let catTypeList = this.CategoryList;
    let catId = id;
    let catStatus = (status == 1) ? 0 : 1;

    let catObj = {
      catId: catId,
      status: catStatus
    }
    $('.customLoader').fadeIn();
    this.adminService.changeCategoryStatus(catObj).subscribe((data: any) => {
      $('.customLoader').fadeOut();
      var res = data.response;
      if (data.status) {
        catTypeList.forEach(data => {
          if (data.id == catId) {
            data.status = catStatus;
          }
        });
        this.CategoryList = catTypeList;
      } else {
        Swal.fire('Oops...', res.msg, 'error')
      }
    });
  }

  /**
 * @desc Sort table values
 * @return json
 */
  sort(value) {
    var userList = this.filteredList;
    if (this.sortValue == value) {
      this.sortOrder = (this.sortOrder == 'asc') ? 'desc' : 'asc';
    }
    this.sortValue = value;
    userList = _.orderBy(userList, [value], [this.sortOrder]); // Use Lodash to sort array by 'name'
    this.filteredList = userList;
  }

}
