import { Component, OnInit } from '@angular/core';
import * as env from '../../../globals/env';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {


    public LOGO: any = env.PROJECT_ADMIN_LOGO_URL;

    constructor() { }

    ngOnInit() {

    }

}
