'use strict';

// server config
export function server_url() {
    let hostDetails = window.location;
    if (hostDetails.hostname == 'admin.thegates.biz') {
        return 'https://admin.thegates.biz';
    } else if (hostDetails.hostname == 'brand.letsnurture.co.uk') {
        return 'http://139.59.84.117:9292';
    } else if (hostDetails.hostname == 'brand.com') {
        return 'http://68.183.97.246:9292';
    } else {
        return 'http://localhost:9292';
    }
    //return 'https://admin.thegates.biz';
};

// project config
export const PROJECT_NAME = 'Brand';
export const PROJECT_LOGO_URL = 'assets/images/brand_logo.png';
export const PROJECT_ADMIN_LOGO_URL = 'assets/images/brand_logo.png';

// set static variables
export const STATIC_PROFILE_IMAGE = 'assets/img/user-placeholder.png';
export const STATIC_POST_IMAGE = 'assets/img/post-placeholder.jpg';
export const STATIC_OFFER_IMAGE = 'assets/img/project-board1.jpg';
