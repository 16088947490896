import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as validator from '../../../../validations/admin/static-pages/staticPage-validation';


declare var ClassicEditor: any;
import Swal from 'sweetalert2';
declare var $: any;


@Component({
  selector: 'app-edit-faq-list',
  templateUrl: './edit-faq-list.component.html',
  styleUrls: ['./edit-faq-list.component.scss']
})
export class EditFaqListComponent implements OnInit {

  public question: any;
  public faqID: any;
  public answer: any;
  public validationErrors: any = {}; // Error object
  public content: any;
  public type: any;

  constructor(
    public adminService: AdminService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {

    ClassicEditor.create(document.querySelector('#answer'))
      .then(editor => {
        this.content = editor;
        console.log(this.content.getData());

      })
      .catch(error => {
      });
    $(document).ready(function () {
      $(".ck-editor__editable").css('height', '200px')
    })

    var bstatus = [{ id: 1, text: "Available" }, { id: 2, text: "How BrandsDotCom Works?" }, { id: 3, text: "Purchasing a Name" }, { id: 4, text: "Leasing a Name" }];

    $('#select1').select2({
      placeholder: "Select type",
      width: '100%',
      data: function () { return { results: bstatus }; }
    });
    
    this.activatedRoute.params.subscribe((params: Params) => {
      let faqId = params['id'];
      this.faqID = faqId;
      let pageObj = {
        faq_id: faqId
      }
      this.adminService.getFAQPageDetails(pageObj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
          this.faqID = res.data.faq_id;
          this.question = res.data.question;
          this.answer = this.content.setData(res.data.answer);
          this.type = $('#select1').val(res.data.type).change();
        }
      });

    });
  }

  updateFAQDetails() {

    let btandObj = {
      question: this.question,
      answer: this.content.getData(),
      type: $("#select1").val()
    };

    let validate = validator.validateFAQDetails(btandObj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};

      let obj = {
        question: this.question,
        answer: this.content.getData(),
        faq_id: this.faqID,
        type: $("#select1").val()
      };
      this.adminService.editFAQPage(obj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          this.router.navigate(['/admin/faq/view']);
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

}

