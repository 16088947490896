'use strict';

import * as Helper from '../../../globals/helper';

// server config
export function validateAddUserType(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Required
    if (Helper.isEmpty(Obj.category)) {
        errros['category'] = 'Catgeory is required ';
        returnValidator.isValid = false;
    }   

    return returnValidator;
};

// server config
export function validateTagType(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Required
    if (Helper.isEmpty(Obj.tag)) {
        errros['tag'] = 'Catgeory is required ';
        returnValidator.isValid = false;
    } 

    return returnValidator;
};

export function validateCertificateComment(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Required
    if (Helper.isEmpty(Obj.comment)) {
        errros['comment'] = 'Comment is required ';
        returnValidator.isValid = false;
    }

    return returnValidator;
};