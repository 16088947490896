'use strict';
import * as Helper from '../globals/helper';

// server config
export function validateLogin(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values
    var username = Obj.username;
    var password = Obj.password;

    // Required
    if (Helper.isEmpty(username)) {
        errros['username'] = 'Username is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(password)) {
        errros['password'] = 'Password is required '
        returnValidator.isValid = false;
    }

    return returnValidator;
};
