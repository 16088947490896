import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import * as env from '../../globals/env';
import * as validator from '../../validations/login-validation';

import Swal from 'sweetalert2'
declare var $: any;



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  public users: any;

  public username: any;
  public password: any;
  public validationErrors: any = {};

  public LOGO: any = env.PROJECT_LOGO_URL;

  constructor(
    public authService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.authService.loggedIn()) {
      this.router.navigate(['/admin/dashboard']);
    }
  }

  onLoginSubmit() {
    let username = this.username;
    let password = this.password;

    let loginObj = {
      username: username,
      password: password
    };

    let validate = validator.validateLogin(loginObj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {}

      var userObj = {
        email: username,
        password: password
      }
      $('.customLoader').fadeIn();
      this.authService.loginUser(userObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          if (res.data.userRole == 1) {
            this.authService.storeUserData(res.token, res.data);
            this.router.navigate(['/admin/dashboard']);
          } else {
            Swal.fire('Oops...', 'Only admin can login here', 'error')
          }
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

}
