import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as env from '../../../../globals/env';
import * as validator from '../../../../validations/admin/brand/add-brand-validation'; //validation
import * as _ from 'lodash';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})


export class OrderListComponent implements OnInit {

  public orderList: any = [];
  public filteredList: any = [];
  public userList: any = [];  
  public ordercount: any = 0;
  public filteredordercount: any = 0;
  public STATIC_PROFILE_IMAGE = env.STATIC_PROFILE_IMAGE;
  public selectAllCheck: Boolean = false;
  public searchText: any;
  public user: any = 0;
  selectedOptions: any = '';

  itemList = [];
  selectedItems = [];
  settings = {};

  // table options
  p: number = 1;
  perPage: number = 10;
  perPageArr: any = [5, 10, 20, 50, 100];
  sortValue: any = 'fullName';
  sortOrder: any = 'asc';
  public validationErrors: any = {}; // Error object

  constructor(
    public adminService: AdminService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.adminService.getOrderListing().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let orderList = res.data;
        this.ordercount = orderList.length
        // this.orderList = orderList.map(data => {
        //   let retObj = {};
        //   retObj['id'] = data.id;
        //   retObj['fullName'] = data.fullName;
        //   retObj['userName'] = data.userName;
        //   retObj['email'] = data.email;
        //   retObj['status'] = data.status;
        //   retObj['phone'] = data.phone;
        //   retObj['usertype'] = data.usertype;
        //   retObj['createdAt'] = data.createdAt;
        //   return retObj;
        // });
        this.filteredList = orderList;
        this.sort('createdAt') // thn dsc            
      }
    });

    this.adminService.getUserListing().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let userList = res.data;
        this.userList = userList.map(data => {
          let retObj = {};
          retObj['id'] = data.id;
          retObj['fullName'] = data.fullName;
          return retObj;
        });      
        $('#select4').select2({
          placeholder: "Select a user",
          width: '100%',
          data: function () { return { results: res.data }; }
        });
      }
    });

  }


  /**
* @desc Sort table values
* @return json
*/
  sort(value) {
    var orderList = this.filteredList;
    if (this.sortValue == value) {
      this.sortOrder = (this.sortOrder == 'asc') ? 'desc' : 'asc';
    }
    this.sortValue = value;
    orderList = _.orderBy(orderList, [value], [this.sortOrder]); // Use Lodash to sort array by 'name'
    this.filteredList = orderList;
  }

  userClick(){

    let objs = {
      user: $('#select4').val()
    };

    let validate = validator.validateOrderFilter(objs);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};
      var obj = {
        user_id: $('#select4').val()
      }
      this.adminService.getFilterOrderListing(obj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
          let orderList = res.data;
          this.ordercount = orderList.length
          // this.orderList = orderList.map(data => {
          //   let retObj = {};
          //   retObj['id'] = data.id;
          //   retObj['fullName'] = data.fullName;
          //   retObj['userName'] = data.userName;
          //   retObj['email'] = data.email;
          //   retObj['status'] = data.status;
          //   retObj['phone'] = data.phone;
          //   retObj['usertype'] = data.usertype;
          //   retObj['createdAt'] = data.createdAt;
          //   return retObj;
          // });
          this.filteredList = orderList;
          this.sort('createdAt') // thn dsc            
        }
      });

    }  
    
  }




}
