'use strict';

import * as Helper from '../../../globals/helper';

// server config
export function validateAboutDetails(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values

    // Required
    if (Helper.isEmpty(Obj.title)) {
        errros['title'] = 'Title is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.title_content)) {
        errros['title_content'] = 'Title Content is required ';
        returnValidator.isValid = false;
    }    
    return returnValidator;
};