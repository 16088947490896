import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { DragDropModule } from '@angular/cdk/drag-drop';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './services/authentication.service';
import { SeoService } from './services/seo.service';
import { CommonService } from './services/common.service';
import { AdminService } from './services/admin.service';
import { TextMaskModule } from 'angular2-text-mask';

import { LoginComponent } from './component/login/login.component';
import { AdminDashboardComponent } from './component/admin/admin-dashboard/admin-dashboard.component';
import { AdminSidebarComponent } from './component/admin/admin-sidebar/admin-sidebar.component';
import { AdminHeaderComponent } from './component/admin/admin-header/admin-header.component';
import { AdminViewUsersComponent } from './component/admin/manage-users/admin-view-users/admin-view-users.component';
import { BrandViewComponent } from './component/admin/manage-brand/brand-view/brand-view.component';
import { BrandListComponent } from './component/admin/manage-brand/brand-list/brand-list.component';
import { BrandEditComponent } from './component/admin/manage-brand/brand-edit/brand-edit.component';
import { BrandAddComponent } from './component/admin/manage-brand/brand-add/brand-add.component';
import { CategoryListComponent } from './component/admin/manage-category/category-list/category-list.component';
import { OrderListComponent } from './component/admin/manage-orders/order-list/order-list.component';
import { OrderViewComponent } from './component/admin/manage-orders/order-view/order-view.component';
import { TagListComponent } from './component/admin/manage-tag/tag-list/tag-list.component';
import { AdminStaticPageEditComponent } from './component/admin/manage-static-pages/admin-static-page-edit/admin-static-page-edit.component';
import { AdminStaticPagesComponent } from './component/admin/manage-static-pages/admin-static-pages/admin-static-pages.component';
import { FilterPipe } from './pipe/filter.pipe';
import { AdminStaticPageAddComponent } from './component/admin/manage-static-pages/admin-static-page-add/admin-static-page-add.component';
import { FaqListComponent } from './component/admin/manage-faq/faq-list/faq-list.component';
import { AddFaqListComponent } from './component/admin/manage-faq/add-faq-list/add-faq-list.component';
import { EditFaqListComponent } from './component/admin/manage-faq/edit-faq-list/edit-faq-list.component';
import { AboutComponent } from './component/admin/about/about.component';
import { UserEditComponent } from './component/admin/manage-users/user-edit/user-edit.component';
import { CertificateComponent } from './component/admin/certificate/certificate.component';
import { UserIdleModule } from 'angular-user-idle';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminDashboardComponent,
    AdminSidebarComponent,
    AdminHeaderComponent,    
    AdminViewUsersComponent,    
    BrandViewComponent,
    BrandListComponent,
    BrandEditComponent,
    BrandAddComponent,
    CategoryListComponent,
    OrderListComponent,
    OrderViewComponent,
    TagListComponent,    
    AdminStaticPageEditComponent,
    AdminStaticPagesComponent,
    FilterPipe,
    AdminStaticPageAddComponent,
    FaqListComponent,
    AddFaqListComponent,
    EditFaqListComponent,
    AboutComponent,
    UserEditComponent,
    CertificateComponent
  ],
  imports: [
    BrowserModule, ReactiveFormsModule, AppRoutingModule, FormsModule, HttpClientModule, NgxPaginationModule, AngularMultiSelectModule, TextMaskModule, DragDropModule, 
  ],
  providers: [AuthenticationService, SeoService, AuthGuard, CommonService, AdminService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
//UserIdleModule.forRoot({ idle: 10, timeout: 10, ping: 0 })