import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as env from '../../../../globals/env';
import * as validator from '../../../../validations/login-validation';
import * as _ from 'lodash';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-admin-static-pages',
  templateUrl: './admin-static-pages.component.html',
  styleUrls: ['./admin-static-pages.component.scss']
})
export class AdminStaticPagesComponent implements OnInit {

  public cmsList: any = [];
  public filteredList: any = [];
  public userData: any;
  public usercount: any = 0;
  public filteredusercount: any = 0;
  public STATIC_PROFILE_IMAGE = env.STATIC_PROFILE_IMAGE;
  public selectAllCheck: Boolean = false;
  public searchText: any;
  selectedOptions: any = '';

  itemList = [];
  selectedItems = [];
  settings = {};

  // table options
  p: number = 1;
  perPage: number = 10;
  perPageArr: any = [5, 10, 20, 50, 100];
  sortValue: any = 'fullName';
  sortOrder: any = 'asc';

  constructor(
    public adminService: AdminService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.adminService.getcmsListing().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let cmsList = res.data;
        this.usercount = cmsList.length
        this.cmsList = cmsList.map(data => {
          let retObj = {};
          retObj['page_id'] = data.page_id;
          retObj['title'] = data.title;
          retObj['slug'] = data.slug;          
          retObj['status'] = data.status;          
          return retObj;
        });
        this.filteredList = this.cmsList;
        this.sort('createdAt') // thn dsc            
      }
    });
  }


  /**
* @desc Sort table values
* @return json
*/
  sort(value) {
    var cmsList = this.filteredList;
    if (this.sortValue == value) {
      this.sortOrder = (this.sortOrder == 'asc') ? 'desc' : 'asc';
    }
    this.sortValue = value;
    cmsList = _.orderBy(cmsList, [value], [this.sortOrder]); // Use Lodash to sort array by 'name'
    this.filteredList = cmsList;
  }

  /**
  * @desc Change user Status
  * @return json
  */
  updateStatus(id, status) {
    let cmsList = this.cmsList;
    let userId = id;
    let userStatus = (status == 1) ? 0 : 1;

    let userObj = {
      userId: userId,
      status: userStatus
    }
    $('.customLoader').fadeIn();
    this.adminService.changeUserStatus(userObj).subscribe((data: any) => {
      $('.customLoader').fadeOut();
      var res = data.response;
      if (data.status) {
        cmsList.forEach(data => {
          if (data.id == id) {
            data.status = userStatus;
          }
        });
        this.cmsList = cmsList;
      } else {
        Swal.fire('Oops...', res.msg, 'error')
      }
    });
  }
  
}

