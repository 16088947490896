import { Component } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { Router, NavigationEnd } from '@angular/router';
import { SeoService } from './services/seo.service';
import { AuthenticationService } from './services/authentication.service';
import * as env from './globals/env';
import { UserIdleService } from 'angular-user-idle';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public PROJECT_NAME: any = env.PROJECT_NAME;
  public user: any;
  
  constructor(
    private router: Router,
    public seoService: SeoService,
    public authService: AuthenticationService,
    meta: Meta,
    titleService: Title,
    private userIdle: UserIdleService
  ) {
  //  this.restart();
    // set title
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let metaInfo = this.seoService.getTitleValue(router.routerState, router.routerState.root);

        var title = (metaInfo[0] !== undefined) ? metaInfo[0] : "";
        var metaDescription = (metaInfo[1] !== undefined) ? metaInfo[1] : "";
        var metaKeywords = (metaInfo[2] !== undefined) ? metaInfo[2] : "";

        titleService.setTitle(title);

        meta.addTags([
          { name: 'author', content: 'Brand' },
          { name: 'description', content: metaDescription },
          { name: 'keywords', content: metaKeywords }
        ]);
      }
    });
  }


  ngOnInit() {
   
    // this.userIdle.startWatching();

    
    // this.userIdle.onTimerStart().subscribe(count => console.log("dfgdfg" + count));

    
    // this.userIdle.onTimeout().subscribe(res => {
    //   localStorage.removeItem('Brand_Dashboard');
    //   this.authService.logout();
    //   this.router.navigate(['/']);
    //   this.stop();
    //   this.stopWatching();
    // });
    
  }

  // stop() {
  //   this.userIdle.stopTimer();
  // }

  // stopWatching() {
  //   this.userIdle.stopWatching();
  // }

  // startWatching() {
  //   console.log('start watching');    
  //   this.userIdle.startWatching();
  // }

  // restart() {
  //   console.log('in');
    
  //   this.userIdle.resetTimer();
  // }

}
