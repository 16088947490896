'use strict';

import * as Helper from '../../../globals/helper';

// server config
export function validateEditUser(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Required
    if (Helper.isEmpty(Obj.fullName)) {
        errros['fullName'] = 'Full Name is required ';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.userName)) {
        errros['userName'] = 'User Name is required ';
        returnValidator.isValid = false;
    }

   
    
    

    return returnValidator;
};
