import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import * as env from '../globals/env';
import { environment } from '../../environments/environment';

@Injectable()
export class CommonService {

    authToken: any;
    user: any;
    baseUrl: any = environment.server_url;

    constructor(private http:HttpClient) { }

    /**
    * Developed Name: Parth Patwala
    * Date: 24-04-2018
    * @desc Get active country list
    *
    * @return json
    */
    getActiveCountryList() {
        let headers = new HttpHeaders();
        headers.append('Content-type', 'application/json');
        return this.http.get(this.baseUrl + '/api/area/activeCountryList', {headers: headers});
    }

    /**
    * Developed Name: Parth Patwala
    * Date: 24-04-2018
    * @desc Get User type list
    *
    * @return json
    */
    getUserTypeList() {
        let headers = new HttpHeaders();
        headers.append('Content-type', 'application/json');
        return this.http.get(this.baseUrl + '/api/user/getActiveUserType', {headers: headers});
    }

    
    /**
    * Developed Name: Dipika
    * Date: 04-05-2018
    * @desc Get Post Category list
    *
    * @return json
    */
    getPostCategoryList() {
        let headers = new HttpHeaders();
        headers.append('Content-type', 'application/json');
        return this.http.get(this.baseUrl + '/api/post/getPostCategoryList', {headers: headers});
    }

    /**
   * Developed Name: Dipika PAtel
   * Date: 24-04-2019
   * @desc Get User type list
   *
   * @return json
   */
    getActiveSportType() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        return this.http.get(this.baseUrl + '/api/user/getActiveSportType', { headers: headers });
    }

}
